.Select-control {
    border: 0;
}

.Select-value {
    padding-left: 0;
}

.ishMfd {
    padding-bottom: 0;
}